export const TopBarConfig = {
    showAlert: true,
    alertText: "Countdown to ECO25! ",
    secondaryAlertText: "Register Now", //Displays after alertText or after countdown timer when active
    clickUrl:
        "https://events.cellcore.com/eco2025?utm_source=cellcore.com&utm_medium=direct&utm_campaign=eco2025&utm_content=ribbon",
    backgroundColor: "#A02EC6",
    textColor: "#FFF",
    fontSize: "16px",
    showCountDown: true,
    endDate: "2025-05-01", // Format: YYYY-MM-DD for better date parsing
    endTime: "08:30",
    expiredText: "Live Now",
    backgroundImage: "",
    removeDate: "2025-05-04", // Format: YYYY-MM-DD - Top bar will be removed after this date
    timezone: "-06:00", // MST timezone offset
    underlineSecondaryText: true, // Controls whether secondary text is underlined
};
