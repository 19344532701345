import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

const TopBarContainer = styled("div")(({ theme, backgroundImage, backgroundColor }) => ({
    background: backgroundImage ? `url(${backgroundImage}) center/cover no-repeat` : backgroundColor,
    padding: "10px 0",
    textAlign: "center",
    fontFamily: "'Gotham Medium', sans-serif",
    fontSize: "16px",
    color: "#721c24",
    transition: "opacity 0.3s ease",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    "&:hover": {
        opacity: 0.9,
    },
}));

const TopBarTextContainer = styled("div")({
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "0 20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    position: "relative",
    whiteSpace: "nowrap",
    overflow: "visible",
    "@media screen and (max-width: 450px)": {
        flexDirection: "column",
        gap: "5px",
        whiteSpace: "normal",
        textAlign: "center",
    },
});

const TopBarLink = styled("a")({
    textDecoration: "none",
    cursor: "pointer",
    color: "inherit",
    fontSize: "inherit",
    width: "100%",
    display: "block",
});

const CountDownTimer = styled("div")({
    fontWeight: "bold",
    position: "relative",
    whiteSpace: "nowrap",
    overflow: "visible",
    "@media screen and (max-width: 450px)": {
        whiteSpace: "normal",
    },
});

const AlertGroup = styled("div")({
    display: "flex",
    alignItems: "center",
    gap: "10px",
    whiteSpace: "nowrap",
    overflow: "visible",
    "@media screen and (max-width: 450px)": {
        whiteSpace: "normal",
    },
});

const TopBar = ({
    showAlert,
    alertText,
    secondaryAlertText,
    clickUrl,
    backgroundColor,
    textColor,
    fontSize,
    showCountDown,
    endDate,
    endTime,
    expiredText,
    backgroundImage,
    removeDate,
    timezone,
    underlineSecondaryText,
}) => {
    const [barShown, setBarShown] = useState(false);
    const [countdown, setCountdown] = useState("");

    useEffect(() => {
        if (!showAlert) {
            setBarShown(false);
            return;
        }

        if (removeDate) {
            const now = new Date();
            const removeDateTime = new Date(removeDate);
            if (isNaN(removeDateTime.getTime())) {
                console.error("Invalid removeDate format. Expected YYYY-MM-DD");
                return;
            }
            if (now >= removeDateTime) {
                setBarShown(false);
                return;
            }
        }

        setBarShown(true);
    }, [showAlert, removeDate]);

    useEffect(() => {
        if (!showCountDown || !endDate || !endTime) return;

        const updateCountDown = () => {
            const now = new Date();
            const endDateTime = new Date(`${endDate}T${endTime}${timezone}`);

            if (isNaN(endDateTime.getTime())) {
                console.error("Invalid date/time format. Expected YYYY-MM-DD and HH:mm");
                return;
            }

            const diff = endDateTime - now;

            if (diff <= 0) {
                setCountdown(expiredText);
                return;
            }

            const days = Math.floor(diff / 86400000);
            const hours = Math.floor((diff % 86400000) / 3600000);
            const minutes = Math.floor((diff % 3600000) / 60000);
            const seconds = Math.floor((diff % 60000) / 1000);

            setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);
        };

        const timer = setInterval(updateCountDown, 1000);
        updateCountDown();

        return () => clearInterval(timer);
    }, [showCountDown, endDate, endTime, expiredText, timezone]);

    return (
        <>
            {barShown && (
                <TopBarLink
                    href={clickUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={`${alertText}${secondaryAlertText ? ` ${secondaryAlertText}` : ""}`}
                    role="alert"
                >
                    <TopBarContainer
                        backgroundImage={backgroundImage}
                        backgroundColor={backgroundColor}
                        style={{ color: textColor, fontSize: `${fontSize}px` }}
                        role="banner"
                    >
                        <TopBarTextContainer>
                            <AlertGroup>
                                {alertText}
                                {showCountDown && (
                                    <CountDownTimer aria-live="off" aria-atomic="false">
                                        {countdown}
                                    </CountDownTimer>
                                )}
                            </AlertGroup>
                            {secondaryAlertText && (
                                <div style={{ textDecoration: underlineSecondaryText ? "underline" : "none" }}>
                                    {secondaryAlertText}
                                </div>
                            )}
                        </TopBarTextContainer>
                    </TopBarContainer>
                </TopBarLink>
            )}
        </>
    );
};

TopBar.propTypes = {
    showAlert: PropTypes.bool,
    alertText: PropTypes.string.isRequired,
    secondaryAlertText: PropTypes.string,
    clickUrl: PropTypes.string,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    fontSize: PropTypes.string,
    showCountDown: PropTypes.bool,
    endDate: PropTypes.string,
    endTime: PropTypes.string,
    expiredText: PropTypes.string,
    backgroundImage: PropTypes.string,
    removeDate: PropTypes.string.isRequired,
    timezone: PropTypes.string,
    underlineSecondaryText: PropTypes.bool,
};

TopBar.defaultProps = {
    showAlert: true,
    secondaryAlertText: "",
    backgroundColor: "#7DC8E3",
    textColor: "#FFF",
    fontSize: "16px",
    showCountDown: false,
    endDate: "",
    endTime: "",
    expiredText: "",
    backgroundImage: "",
    removeDate: "",
    timezone: "-06:00",
    underlineSecondaryText: false,
};

export default TopBar;
